.social-link {
  width: 2.5em;
  height: 2.5em;

  :hover {
    cursor: pointer;
  }

  &.linkedin {
    outline: none;
    border-radius: 5px;
    background-color: #f2f3f4;
  }
}
