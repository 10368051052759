.nav {
  position: relative;
  width: 100vw;
  height: 45px;
  background-color: rgb(50, 51, 51);
  margin: 0;
  padding: 0;

  .nav-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  .nav-item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    color: white;
    cursor: pointer;
    height: 100%;
    align-items: center;
    text-align: center;
    vertical-align: middle;
  }

  .selected-icon {
    transition: all 0.3s ease-out;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #f2f3f4 transparent;
    margin-top: 5px;
    position: relative;
    top: 15px;
    left: -50px;

    &.hidden {
      opacity: 0;
    }
  }
}

@media all and (max-width: 768px) {
  .nav {
    display: none;
  }
}
