.projects {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    outline: none;
    transition: all ease-in-out 0.3s;
    width: 80%;
    color: #000;
    margin-bottom: 10px;
  }

  .button:hover {
    cursor: pointer;
    border: 1px solid transparent;
    background-color: rgb(51, 51, 51);
    color: #fff;
    transition: all ease-in-out 0.3s;
  }

  .flip-card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 320px;
    perspective: 1000px;
    padding: 5px;
    cursor: pointer;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;

    &.flipped {
      transform: rotateY(180deg);
    }
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    line-height: 1.5rem;
    background: linear-gradient(to bottom right, #107896, #1287A8 );    
    border-radius: 5px;
    color: #000;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      text-align: center;
      font-weight: bold;
      color: #f2f3f4;
    }

    .body {
      font-size: 14px;
      padding: 10px;
      text-align: center;
      color: #f2f3f4;
    }
  }

  .flip-card-back {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom right, #757573,#a6a6a2);   
    border-radius: 5px;
    color: white;
    transform: rotateY(180deg);

    .body {
      font-size: 14px;
      padding: 10px;
      line-height: 2rem;
    }
  }
}

@media (max-width: 320px) {
  .projects {
    .flip-card-back {
      justify-content: space-between;
    }
    .flip-card {
      width: 320px;
      height: 340px;
    }
  }
}
