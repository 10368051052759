.mobile-nav {
  display: none;
  position: relative;
  width: 100vw;
  height: 45px;
  background-color: rgb(51, 51, 51);
  margin: 0;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 10px 0 10px;

  .icon {
    cursor: pointer;
  }

  .nav-options {
    position: absolute;
    top: 45px;
    right: 0px;
    display: flex;
    flex-direction: column;
    background-color: rgb(51, 51, 51);
    padding: 20px;
    min-width: 150px;
    z-index: 3;

    .nav-item {
      display: flex;
      align-items: center;
      padding: 10px;

      span {
        margin-left: 5px;
      }
    }
  }

  .current-menu {
    display: flex;
    align-items: center;
  }
}

@media all and (max-width: 768px) {
  .mobile-nav {
    display: flex;
  }
}
