.modal {
  position: fixed;
  top: 50%;
  min-width: 500px;
  left: 50%;
  max-height: calc(100vh - 40px);
  transform: translate(-50%, -50%);
  padding: 20px 10px 10px 10px;
  background-color: #f2f3f4;
  border-radius: 5px;
  z-index: 40;
  font-size: 12px;
  & > .body {
    overflow-y: auto;
    margin: 20px 0;
    max-height: 50vh;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .cross-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border-radius: 100%;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: #931000;

    &:hover {
      color: #931000;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 1000vw;
  height: 1000vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;

  .button:not(:last-child) {
    margin-right: 10px;
  }
}

@media all and (max-width: 426px) {
  .modal {
    max-width: 300px;
    min-width: 300px;

    h1 {
      font-size: 2.5rem;
    }
  }
}
