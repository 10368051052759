.header-content {
  width: 100vw;
  height: auto;
  background: #f2f3f4;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  h1 {
    font-weight: 100;
  }

  .header-image {
    margin-top: 10px;
    max-width: inherit;
    display: inherit;
    border-radius: 7px;
    z-index: 1;
    -webkit-box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.7);
    -moz-box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.7);
    box-shadow: 0px 0px 20px 3px rgba(51, 51, 51, 0.7);
  }

  .social-link-container {
    width: calc(2.5em * 5 + 0.6em);
    display: inherit;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px 0;
  }
}
