.experience {
  .logo {
    width: 25%;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      &.ibm-mq {
        margin-left: 15px;
        width: 12%;
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
  }

  .spaced {
    margin-top: 4rem;
  }

  .content {
    border-radius: 7px;
    padding: 15px;
    line-height: 2rem;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    -webkit-box-shadow: 0px 0px 1px 1px #000000;
    -moz-box-shadow: 0px 0px 1px 1px #000000;
    box-shadow: 0px 0px 1px 1px #000000;

    ul {
      list-style-type: square;
      margin-left: 20px;
      padding-left: 0px;

      li {
        margin-bottom: 15px;
      }
    }
  }
}
