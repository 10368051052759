.nav-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;

  .nav-row {
    display: flex;
    flex: 1;
    margin-left: 20px;
    border-bottom: 1px solid #c1c1c1;

    &:last-child {
      margin-bottom: 20px;
      border-bottom: none;
    }

    .nav-cell {
      display: flex;
      flex: 1;
      border-radius: 5px;
      margin: 20px 20px 0 0;

      .nav-cell-title {
        min-width: 80px;
      }

      ol {
        list-style-type: none;
      }

      li {
        position: relative;
        margin: 0;
        padding-bottom: 1em;
        padding-left: 30px;
        text-align: left;
      }

      li:before {
        content: "";
        background-color: #000;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 6px;
        width: 3px;
      }

      li:after {
        content: "";
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'><circle stroke='none' fill='%23000' cx='16' cy='16' r='10'></circle></svg>");
        position: absolute;
        left: 0;
        height: 15px;
        width: 15px;
      }

      img {
        margin-bottom: 5px;
      }

      &.with-logo {
        flex-direction: column;
      }

      &:first-child {
        max-width: 150px;
      }
    }
  }
}

.no-content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 767px) {
  .nav-content .nav-row .nav-cell .nav-cell-title {
    width: 65px;
  }

  .nav-row {
    display: flex;
    flex-direction: column;

    .nav-cell {
      &:first-child {
        align-self: center;
      }
    }
  }

  @media all and (max-width: 375px) {
    .nav-content .nav-row .nav-cell .nav-cell-title {
      width: 25px;
      min-width: 30px;
    }
  }
}
