html,
body {
  font-family: Geneva, Verdana, sans-serifs;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.width-content {
  max-width: 1150px;
  padding: 0px 80px;
  margin: 0 auto;
}

.height-content {
  padding: 40px 0px;
}

@media all and (max-width: 768px) {
  .width-content {
    max-width: 1080px;
    padding: 0px 40px;
    margin: 0 auto;
  }

  .height-content {
    padding: 20px 0px;
  }
}

@media all and (max-width: 425px) {
  .width-content {
    max-width: 1080px;
    padding: 0px 20px;
    margin: 0 auto;
  }

  .height-content {
    padding: 10px 0px;
  }
}

@import "./app.scss";
@import "./components/header/styles/header.scss";
@import "./components/nav/styles/nav.scss";
@import "./components/nav/styles/mobile-nav.scss";
@import "./components/nav-content/styles/nav-content.scss";
@import "./components/nav-content/styles/education.scss";
@import "./components/nav-content/styles/experience.scss";
@import "./components/nav-content/styles/projects.scss";
@import "./components/nav-content/styles/extras.scss";
@import "./components/social/styles/social-link.scss";
@import "./components/modal/styles/custom-modal.scss";
