.extras {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }

  .content {
    border-radius: 7px;
    line-height: 2rem;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
    -webkit-box-shadow: 0px 0px 1px 1px #333333;
    -moz-box-shadow: 0px 0px 1px 1px #333333;
    box-shadow: 0px 0px 1px 1px #333333;
  }
}
