.education {
  .logo {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 150px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .content {
    border-radius: 7px;
    line-height: 2rem;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
    -webkit-box-shadow: 0px 0px 1px 1px #931000;
    -moz-box-shadow: 0px 0px 1px 1px #931000;
    box-shadow: 0px 0px 1px 1px #931000;
  }

  table,
  td,
  th {
    border: 1px solid #931000;
    text-align: left;
    table-layout: fixed;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 15px;
  }

  th {
    color: #931000;
  }

  .education-nav {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    padding-left: 0px;

    .nav-item {
      border: 1px solid #c6c7c8;
      padding: 10px;
      border-radius: 5px;
      background-size: 200% 200%;
      transition: all ease-in-out 0.3s;

      &.selected {
        background-color: #931000;
        border: 1px solid transparent;
        color: #fff;
        transition: all ease-in-out 0.3s;
      }
    }

    .nav-item:hover {
      cursor: pointer;
      background-color: #931000;
      -webkit-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;
      border: 1px solid transparent;
      color: #fff;
    }
  }

  .grade {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #931000;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-size: 16px;
  padding: 15px 32px;
  transition: all ease-in-out 0.3s;

  &.selected {
    border: none;
    background-color: #931000;
    color: #f2f3f4;
    transition: all ease-in-out 0.3s;
  }

  &.full-width {
    width: 100%;
  }
}

.view-module-container {
  padding: 10px;
  text-align: center;
  line-height: 2rem;

  .item-container {

    hr {
      border-top: 1px solid #8a0000;
      width: 25%;
    }

    p:first-child {
      margin-top: 15px;
    }
  }
}

.button:hover {
  cursor: pointer;
  background-color: #871000;
  color: #fff;
  transition: all ease-in-out 0.3s;
}

@media all and (max-width: 375px) {
  .education {
    .logo {
      height: 120px;
    }

    .view-module-container {
      padding: 5px;
      text-align: center;
      line-height: 1.5rem;
      ul {
        padding-left: 20px;
        list-style-type: square;
        text-align: center;

        li {
          text-align: center;
          padding: 2px;
        }
      }
    }
  }

  table,
  td,
  th {
    font-size: 12px;
  }
}

@media all and (max-width: 320px) {
  .education {
    .education-nav {
      .nav-item {
        margin-right: 5px;
      }
    }
  }

  .view-module-container {
    padding: 5px;
    text-align: center;
    line-height: 1.5rem;
    ul {
      list-style-type: square;
      text-align: center;

      li {
        text-align: center;
        padding: 2px;
      }
    }
  }

  table,
  td,
  th {
    font-size: 10px;
  }
}

@media all and (max-width: 425px) {
  .education {
    .education-nav {
      .nav-item {
        margin-right: 5px;
      }
    }
  }

  .view-module-container {
    padding: 5px;
    text-align: center;
    line-height: 1.5rem;
    ul {
      padding-left: 20px;
      text-align: center;
      list-style-type: square;

      li {
        text-align: center;
        padding: 2px;
      }
    }
  }

  table,
  td,
  th {
    font-size: 11px;
  }
}
