.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;

  .main-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    .logo {
      width: 80px;

      &.large {
        width: 110px;
      }

      &.extra-large {
        width: 130px;
      }
    }
  }

  @media (max-width: 700px) {
    .main-container {
      flex-direction: column;
    }
  }
}
